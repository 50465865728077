import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const GlobalStyle = createGlobalStyle`
  html { 
    height: 100%;
  }
  body { 
    height: 100%;
    padding: 0;
    margin: 0;
    background: #efefef;
  }
  #___gatsby {
    height: 100%;
  }
  #gatsby-focus-wrapper {
    height: 100%;
  }
`;

const Container = styled.div`
  height: 100%;
`;

const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const FlexDiv = styled.div`
  flex: 1;
`;

const MainImageDiv = styled.div`
  transition: transform 0.15s ease;
  &:hover {
    transform: scaleX(1.1) scaleY(1.1);
  }
`;

const IndexPage = () => {
  return (
    <Container>
      <title>Dinky Red Panda</title>
      <GlobalStyle />
      <ImageContainer>
        <FlexDiv />
        <MainImageDiv>
          <a href='https://stephenallred.com/'>
            <StaticImage
              alt='Dinky Red Panda'
              src='../images/pixel_panda.png'
              loading='eager'
              placeholder='none'
              layout='fixed'
              width={143}
              height={89}
              formats={['png']}
            />
          </a>
        </MainImageDiv>
        <FlexDiv />
        <StaticImage
          alt='Red Panda Face'
          src='../images/pixel_panda_small.png'
          loading='eager'
          placeholder='none'
          layout='fixed'
          width={53}
          height={41}
          quality={95}
          style={{ marginBottom: 16 }}
          formats={['png']}
        />
      </ImageContainer>
    </Container>
  );
};

export default IndexPage;
